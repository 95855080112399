var generic = window.generic || {};
var site = window.site || {};

(function ($) {
  Drupal.behaviors.localeInterstitial = {
    attach: function (context) {
      var prefCountry = '';
      var prefLanguage = '';
      var isRendered = 0;
      var $interstitialContainer = $('.js-locale-interstitial', context);
      var $countryContainer = $('.js-locale-interstitial .country-list', context);
      var $countryLink = $('.js-locale-interstitial a.country-link', context);
      var $selectedCountry = $('.current-country', context);
      var $languageList = $('.js-locale-interstitial .language-list', context);
      var $languageLink = $('.js-locale-interstitial .language-list li', context);
      var $localeButton = $('.js-locale-interstitial .js-chooseLocaleButton', context);
      var resizeEvt;
      var url;
      var country;
      var lang;
      var locale;
      var localeParts;
      var $countryLangs;
      var newHref;
      var analyticsHref;
      var analyticsBrandLocale;
      var obj;

      if (window.location.hash === '#change_country') {
        url = window.location.href.split('/');

        if (url[3] && url[4]) {
          country = url[3];
          lang = url[4].split('#')[0];

          site.changeLocale(country, lang);
        }
      }
      // Get locale settings from browser if it's set
      locale = generic.cookie('LOCALE');

      function countrySelector($countryElement) {
        $countryContainer.find('input[type=radio]').prop('checked', false);
        $countryElement.find('input[type=radio]').prop('checked', true);
        prefCountry = $countryElement.data('country').toUpperCase();
        $countryLink.removeClass('selected');
        $countryElement.addClass('selected');
        $selectedCountry.html($countryElement.data('countryname'));
        $languageLink.removeClass('selected');
        $countryLangs = $countryElement.data('languages').split(',');

        if (!prefLanguage || $countryLangs.indexOf(prefLanguage) === -1) {
          // Set preferred language to country's default if not defined or not available
          prefLanguage = $countryLangs[0];
        }
        $languageList.html(site.getLangsFromCountryLink($countryElement).join(''));
        $languageLink.removeClass('selected current-lang');
        $interstitialContainer.find('.language-list li').each(function () {
          var $lanLink = $(this);

          $lanLink.append('<br><input type="radio" class="language-list__select">');
          if ($lanLink.data('language') === prefLanguage) {
            $lanLink.trigger('click');
          }
        });
        if (prefCountry && prefLanguage) {
          // Enable the choose locale button once both locale variables are set
          $localeButton.attr('disabled', false);
        } else {
          // Disable the choose locale button unless both locale variables are set
          $localeButton.attr('disabled', true);
        }
        $('.js-locale-interstitial .initial-hide', context).show();
        site.centerModal($interstitialContainer);
      }

      function setOverlayLocale(overlayLocale) {
        if (!overlayLocale) {
          // Disable the choose locale button unless a locale is provided
          $localeButton.attr('disabled', true);
        } else {
          // Default country and language selections
          locale = overlayLocale;
          // Don't need local variable
          localeParts = locale.split('_');

          prefLanguage = localeParts[0].toLowerCase();
          prefCountry = localeParts[1].toLowerCase();
        }

        if (prefCountry && prefLanguage) {
          // Enable the choose locale button once both locale variables are set
          $localeButton.attr('disabled', false);
        } else {
          // Disable the choose locale button unless both locale variables are set
          $localeButton.attr('disabled', true);
        }

        site.setupLanguageMenu(prefCountry, prefLanguage);

        if (prefCountry !== '') {
          $('.js-locale-interstitial .country-list a.country-link', context).each(function () {
            var $countryElement = $(this);

            if ($countryElement.data('country') === prefCountry) {
              countrySelector($countryElement);
            }
          });
        }

        // Finally, append the interstitial to the end of the page
        $('body').append($('.js-locale-overlay').css({ display: 'block' }));
        site.centerModal($interstitialContainer);
      }

      function formatLocaleOptions() {
        $('.js-locale-interstitial li a', context).each(function () {
          var $lanLink = $(this);
          var text = $lanLink.html();

          $lanLink.empty();
          $lanLink.append(`${text}<br><input type="radio" class="language-list__select">`);
        });
        $(
          '.js-locale-interstitial .country-list, .js-locale-interstitial .language-list',
          context
        ).cleanWhitespace();
        isRendered = 1;
      }

      function displayLocaleInterstitial() {
        generic.jsonrpc.fetch({
          method: 'locale.detect',
          params: [],
          onSuccess: function (jsonRpcResponse) {
            setOverlayLocale(jsonRpcResponse.getValue());
          },
          onFailure: function () {
            setOverlayLocale();
          }
        });
        if (isRendered === 0) {
          // Ensure that function is triggered only once.
          formatLocaleOptions();
        }
        site.centerModal($interstitialContainer);
      }

      $('body').on('displayLocaleInterstitial', function () {
        // Action to let other modules show the enterstitial (e.g. mobile country picker)
        displayLocaleInterstitial();
      });

      $countryLink.on('click', function () {
        var $countryElement = $(this);

        countrySelector($countryElement);
      });

      $('body').on('click', '.js-locale-interstitial .language-list li', function () {
        var $countryElement = $(this);

        $languageList.find('input[type=radio]').prop('checked', false);
        $countryElement.find('input[type=radio]').prop('checked', true);
        prefLanguage = $countryElement.data('language');
        $languageList.find('li').removeClass('selected current-lang');
        $countryElement.addClass('selected');
        if (prefCountry && prefLanguage) {
          // Enable the choose locale button once both locale variables are set
          $localeButton.attr('disabled', false);
        } else {
          // Disable the choose locale button unless both locale variables are set
          $localeButton.attr('disabled', true);
        }
      });

      $localeButton.on('click', function () {
        site.changeLocale(prefCountry, prefLanguage);
        newHref = window.location.protocol + '//' + window.location.host.replace(/\/$/u, '');
        analyticsHref = newHref + '/' + prefCountry.toLowerCase() + '/' + prefLanguage;
        analyticsBrandLocale = Drupal.settings.site_name + ' ' + prefCountry;
        obj = {
          hub_referrer: document.referrer,
          country_code: prefCountry,
          brand_locale_space: analyticsBrandLocale,
          location: analyticsHref
        };

        site.track.evtView(obj);
      });

      $(window).on(
        'resize',
        _.debounce(function () {
          clearTimeout(resizeEvt);
          resizeEvt = setTimeout(function () {
            site.centerModal($interstitialContainer);
          }, 250);
        })
      );
    }
  };
})(jQuery);
